<template lang="pug">
template(v-for="pricing in pricingList")
  .col-12.col-sm-12.col-md-6.col-lg-4.col-xl-4.mb-4.mb-md-0(:class="pricing.annual === 'true' ? 'annual' : '' ")
    .card.border-primary.content
      div(:class="pricing.annual === 'true' ? 'card-header text-center bg-black text-primary' : '' ")
        template(v-if="pricing.annual === 'true'") Best value
      .card-body.h-100
        .text-center.pb-3
          .fs-3.card-title.fw-bold.mb-0 {{ pricing.plan }}
          .card-text.pb-4 {{ pricing.planDesc }}
          .fs-2.fw-bold.lh-1 RM{{ pricing.price }}
          span.text-middle {{ pricing.month }}
        .px-xl-5
          table.table
            tbody
              template(v-for="content in pricing.details")
                tr
                  th
                    svg.bi(width='24' height='24')
                      use(:xlink:href=" '#' + content.xlink")
                  td
                    span.fw-bold(:class="content.bonus === true ? 'line-break' : '' ")  {{ content.textBold }}&nbsp;
                    template(v-for="subcontent in content.textRegular")
                      template(v-if="content.type === 'span'")
                        span {{ subcontent }}
                      template(v-else-if="content.type === 'p'")
                        p.mb-0 {{ subcontent }}

      .card-body.text-center
        a(href="/")
          button.btn.w-100.bg-primary Download app now
</template>

<script>
export default {
  name: "PricingList",
  data() {
    return {
      pricingList: [
        {
          annual: "false",
          plan: "Free Plan",
          planDesc: "Basic to get started",
          price: "0",
          month: "Per month",
          details: [
            {
              xlink: "check",
              textBold: "Personalised page ",
              type: "span",
              textRegular: "(yourname.kloser.app)",
            },
            {
              xlink: "check",
              textBold: "Up to six",
              type: "span",
              textRegular: "website listing",
            },
            {
              xlink: "cancel",
              textBold: "Automated ad campaigns",
              textRegular: "",
            },
            {
              xlink: "cancel",
              textBold: "Performance data & report",
              textRegular: "",
            },
          ],
        },
        {
          annual: "false",
          plan: "Monthly Plan",
          planDesc: "Let's get you some leads",
          price: "40",
          month: "Per month",
          details: [
            {
              xlink: "check",
              textBold: "Personalised page ",
              type: "span",
              textRegular: "(yourname.kloser.app)",
            },
            {
              xlink: "check",
              textBold: "Unlimited",
              type: "span",
              textRegular: "product listing",
            },
            {
              xlink: "check",
              textBold: "Automated ad campaigns",
              textRegular: "",
            },
            {
              xlink: "check",
              textBold: "Performance data & report",
              textRegular: "",
            },
          ],
        },
        {
          annual: "true",
          plan: "Annual Plan",
          planDesc: "Everything you need to manage your leads",
          price: "30",
          month: "Per Month ( You saved 25%)",
          details: [
            {
              xlink: "check",
              textBold: "Personalised page ",
              type: "span",
              textRegular: "(yourname.kloser.app)",
            },
            {
              xlink: "check",
              textBold: "Unlimited",
              type: "span",
              textRegular: "product listing",
            },
            {
              xlink: "check",
              textBold: "Automated ad campaigns",
              textRegular: "",
            },
            {
              xlink: "check",
              textBold: "Performance data & report",
              textRegular: "",
            },
            {
              xlink: "check",
              textBold: "Bonus:",
              type: "p",
              textRegular: [
                " -\u00A0 Get first dips on new features",
                " -\u00A0 Get exclusive content and templates for annual subscribers only",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>
