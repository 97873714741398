<template lang="pug">
svg.bi.bi-x-lg(xmlns='http://www.w3.org/2000/svg' style='display: none')
  symbol#cancel( viewBox='0 0 16 16')
    path(fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z')
    path(fill-rule='evenodd' d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z')

svg(xmlns='http://www.w3.org/2000/svg' style='display: none;')
  symbol#check(viewBox='0 0 16 16')
    path(d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z')
Navbar
.padding-top
  .pb-4.pb-md-0
    HeadingLayout(:title="title", :desc="desc")
.container.pricing
  .row.pb-5
    PricingList
Footer
</template>

<script>
import Footer from "../components/TheFooter";
import Navbar from "../components/TheNavbar";
import HeadingLayout from "../layout/HeadingLayout";
import PricingList from '../components/PricingList';
export default {
  name: "Pricing",
  components: { PricingList, HeadingLayout, Navbar, Footer },
  data() {
    return {
      title: "Create the best experiences with the right plan.",
      desc: "",
    };
  },
};
</script>
